import { ref } from '@vue/composition-api'
import { isObject } from '@core/utils/utils'
import store from '@/store'
import jwtDefaultConfig from '@/auth/jwt/jwtDefaultConfig'
import Filter from './Filter'

export default class Customers extends Filter {
    filterComponent = import('@/views/components/filters/CustomersFilterComponent.vue')

    static CURRENCY = 'currency_id'

    static COMPANY_NAME = 'cust_name_dba'

    static PSEUDONYM = 'pseudonym'

    static ACCOUNT_MANAGER = 'account_manager_id'

    static STATUS = 'is_active'

    static CONTACT = 'contact'

    static ADDRESS = 'address'

    static CONTACT_PHONE = 'phone_one'

    static CONTACT_FIRSTNAME = 'first_name'

    static CONTACT_LASTNAME = 'last_name'

    static CONTACT_EMAIL = 'email'

    static ADDRESS_STREET = 'street'

    static ADDRESS_CITY = 'city'

    static ADDRESS_COUNTRY = 'country_id'

    static ADDRESS_STATE = 'state_id'

    static ALLOW_RENTALS = 'allow_rentals'

    static ALLOW_TO_RENT_FROM_SUBRENT = 'allow_to_rent_from_subrent'

    static ALLOW_TO_PURCHASE_FROM_SUPPLIER = 'allow_to_purchase_from_supplier'

    static BALANCE_DUE = 'balance_due'

    static ID_SCAN_VERIFIED = 'id_scan_verified'

    static ID_SCAN_NOT_VERIFIED = 'id_scan_not_verified'

    static ACCOUNT_SETUP_INCOMPLETE = 'acount_setup_incomplete'

    static ID_SCAN_FAILED = 'id_scan_failed'

    fields = {
      [Customers.COMPANY_NAME]: {
        label: 'Company Name / DBA',
      },
      [Customers.STATUS]: {
        label: 'Status',
        store: 'statusList',
        firstSelect: JSON.parse(localStorage.getItem(jwtDefaultConfig.settingCustomerDefaultFormKeyName)).is_searches_show_inactive_records ? null : true,
        mapping(value) {
          if (isObject(value)) {
            return Number(value.id)
          }
          // eslint-disable-next-line no-nested-ternary
          // return value !== null ? Number(value) : 1
          return JSON.parse(localStorage.getItem(jwtDefaultConfig.settingCustomerDefaultFormKeyName)).is_searches_show_inactive_records ? null : 1
        },
      },
      [Customers.PSEUDONYM]: {
        label: 'Pseudonym',
      },
      [Customers.ALLOW_RENTALS]: {
        label: 'Allow Rentals',
        selectedValue: this.selectedOnlyLabel,
      },
      [Customers.ALLOW_TO_RENT_FROM_SUBRENT]: {
        label: 'Allow to Rent From (sub-rent)',
        selectedValue: this.selectedOnlyLabel,
      },
      [Customers.ALLOW_TO_PURCHASE_FROM_SUPPLIER]: {
        label: 'Allow to Purchase from (supplier)',
        selectedValue: this.selectedOnlyLabel,
      },
      [Customers.BALANCE_DUE]: {
        label: 'Balance Due',
        selectedValue: this.selectedOnlyLabel,
      },
      [Customers.ID_SCAN_VERIFIED]: {
        label: 'ID Scan Verified (passed)',
        selectedValue: this.selectedOnlyLabel,
      },
      [Customers.ID_SCAN_NOT_VERIFIED]: {
        label: 'ID Scan Not Verified',
        selectedValue: this.selectedOnlyLabel,
      },
      [Customers.ACCOUNT_SETUP_INCOMPLETE]: {
        label: 'Account Setup Incomplete',
        selectedValue: this.selectedOnlyLabel,
      },
      [Customers.ID_SCAN_FAILED]: {
        label: 'ID Scan Failed',
        selectedValue: this.selectedOnlyLabel,
      },
      [Customers.ACCOUNT_MANAGER]: {
        label: 'Account Manager',
        store: 'accountManagerList',
        options: {
          'get-option-label': option => `${option.firstname} ${option.lastname}`,
        },
        selectedLabel(obj) {
          return isObject(obj) ? `${obj.firstname} ${obj.lastname}` : obj
        },
      },

      [Customers.CONTACT]: {
        [Customers.CONTACT_PHONE]: {
          label: 'Contact Phone',
        },
        [Customers.CONTACT_FIRSTNAME]: {
          label: 'Contact Firstname',
        },
        [Customers.CONTACT_LASTNAME]: {
          label: 'Contact Lastname',
        },
        [Customers.CONTACT_EMAIL]: {
          label: 'Contact Email',
        },
      },
      [Customers.ADDRESS]: {
        [Customers.ADDRESS_CITY]: {
          label: 'City',
        },
        [Customers.ADDRESS_STATE]: {
          label: 'State',
          store: 'stateList',
        },
        [Customers.ADDRESS_COUNTRY]: {
          label: 'Country',
          store: 'countryList',
        },
        [Customers.ADDRESS_STREET]: {
          label: 'Street',
        },
      },
    }

    selectedFilters = ref()

    selectedOnlyLabel(label, value) {
      if (value) return super.selectedOnlyLabel(label)
      return null
    }

  // getStatusDefaultValue() {
  //   store.dispatch('listModule/settingCustomerDefault')
  //     return store.state.listModule.customerDefault.is_searches_show_inactive_records;
  // }
}
